<template>
  <div>
    <form @submit.prevent="updateProfile">
      <div class="row profile-outer-page-container">
        <div class="col-12 col-lg-8">
          <input-control
            v-model="(providerData.user || {}).first"
            required
            labelClass="col-md-3"
            type="horizontal"
            :disabled="user && (user.isPatient || user.isProvider)"
            >First Name</input-control
          >
          <span
            class="hintText col-md-8 offset-0 offset-md-3"
            v-if="user && user.isProvider"
          >
            To change this field contact your administrator</span
          >
          <input-control
            v-model="(providerData.user || {}).last"
            required
            labelClass="col-md-3"
            type="horizontal"
            :disabled="user && (user.isPatient || user.isProvider)"
            >Last Name</input-control
          >
          <span
            class="hintText col-md-8 offset-0 offset-md-3"
            v-if="user && user.isProvider"
          >
            To change this field contact your administrator</span
          >
          <input-control
            v-model="(providerData.user || {}).email"
            required
            type="horizontal"
            labelClass="col-md-3"
            control="email"
            :disabled="user && user.isPatient"
            >Email</input-control
          >
          <input-control
            v-model="(providerData.user || {}).phone_number"
            type="horizontal"
            labelClass="col-md-3"
            :cleave="cleave.phone"
            required
            :disabled="user && user.isPatient"
            >Phone Number</input-control
          >

          <div class="form-group row">
            <label
              class="administrator-id-label col-md-3 col-form-label"
              for="administrator_ids"
              >Provider Administrators</label
            >
            <div class="col">
              <vue-tags-input
                id="administrator_ids"
                name="administrator_ids"
                class="form-control"
                v-model="tag"
                :tags="adminTags"
                :autocomplete-items="filteredItems"
                :add-only-from-autocomplete="true"
                :autocomplete-min-length="0"
                :disabled="!user || (!user.isAdmin && !user.isManagingAdmin)"
                placeholder=""
                @tags-changed="(newTags) => (adminTags = newTags)"
              />
            </div>
          </div>

          <ToggleConfidentailData
            fieldName="sin"
            fieldLabel="S.I.N"
            labelClass="col-md-3"
            v-bind:value="providerData.sin"
            v-if="user && !user.isPatient"
            v-on:input="providerData.sin = $event && $event.replaceAll(' ', '')"
          />

          <input-control
            v-model="(providerData.user || {}).hstNumber"
            type="horizontal"
            labelClass="col-md-3"
            v-if="user && !user.isPatient"
            >HST Number</input-control
          >
          <input-control
            v-model="providerData.hcai_registration_id"
            type="horizontal"
            labelClass="col-md-3"
            v-if="user && !user.isPatient"
            >HACI Registration ID</input-control
          >
          <div class="form-group row" v-if="user && !user.isPatient">
            <label class="col-md-3" for="input-control-birth_date"
              >Birth Date</label
            >
            <div class="col">
              <datetime
                id="input-control-birth_date"
                v-model="(providerData.user || {}).birth_date"
                placeholder=""
                :week-start="7"
                zone="UTC"
                format="yyyy-MM-dd"
                input-class="form-control"
              ></datetime>
            </div>
          </div>
          <input-control
            type="horizontal"
            labelClass="col-md-3"
            v-model="(providerData.user || {}).bio"
            control="textarea"
            :disabled="user && user.isPatient"
            >Bio</input-control
          >
          <div class="form-group row" v-if="user && !user.isPatient">
            <label class="col-md-3" for="input-control-hiredAt">Hired On</label>
            <div class="col">
              <datetime
                id="input-control-hiredAt"
                v-model="providerData.hired_at"
                placeholder=""
                :week-start="7"
                zone="UTC"
                format="yyyy-MM-dd"
                input-class="form-control"
              ></datetime>
            </div>
          </div>

          <div
            class="form-group row"
            v-if="user && (user.isAdmin || user.isManagingAdmin)"
          >
            <label class="col-md-3" for="input-control-ExitAt">Exit On</label>
            <div class="col">
              <datetime
                id="input-control-ExitAt"
                v-model="providerData.exited_at"
                placeholder=""
                :week-start="7"
                zone="UTC"
                format="yyyy-MM-dd"
                input-class="form-control"
              ></datetime>
            </div>
          </div>

          <select-control
            v-model="(providerData.user || {}).status"
            :options="statuses"
            required
            v-if="user && (user.isAdmin || user.isManagingAdmin)"
            labelClass="col-md-3 col-12"
            >Status</select-control
          >
          <input-control
            type="horizontal"
            v-model="providerData.exitReason"
            control="textarea"
            labelClass="col-md-3"
            v-if="user && (user.isAdmin || user.isManagingAdmin)"
            >Exit Reason</input-control
          >

          <div
            class="form-group row mt-5 pt-3 pl-3 pl-lg-0"
            v-if="user && user.isProvider && providerData.user_id == user.id"
          >
            <div class="col-lg-3"></div>
            <label class="ml-xl-3 mr-xl-4 mb-0"
              >Receive Notifications Via</label
            >
            <div class="d-flex justify-content-start col mr-2">
              <div class="custom-control custom-switch ml-4 mr-5">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="sendingSMS"
                  v-model="(providerData.user || {}).enable_sending_sms"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="sendingSMS"
                  >SMS</label
                >
              </div>
              <div class="custom-control custom-switch ml-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="sendingEmail"
                  v-model="(providerData.user || {}).enable_sending_email"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="sendingEmail"
                  >Email</label
                >
              </div>
            </div>
          </div>

          <!-- <div class="form-group row mb-3" v-if="user && user.isProvider">
            <div class="col-md-2"></div>
            <div class="row col-auto align-items-center">
              <label
                for="input-control-enable-second-verification"
                class="m-0 ml-3 mr-3 pointer-cursor"
                >Enable Second Step Verification</label
              >
              <input
                type="checkbox"
                id="input-control-enable-second-verification"
                v-model="(providerData.user || {}).enableSecondStepVerification"
                class="form-control pointer-cursor"
              />
            </div>
          </div> -->

          <div
            class="form-group row"
            v-if="user && (user.isAdmin || user.isManagingAdmin)"
          >
            <div class="col-md-3"></div>
            <div class="row col-auto align-items-center">
              <label
                for="input-control-can-verify"
                style="min-width: 217px"
                class="m-0 ml-3 mr-3 pointer-cursor"
                >Ability To Verify Events</label
              >
              <input
                type="checkbox"
                id="input-control-can-verify"
                v-model="providerData.can_verify_event"
                class="form-control pointer-cursor"
              />
            </div>
          </div>

          <div
            class="offset-lg-3 col p-0 profile-action-container"
            v-if="
              user &&
              (user.isProvider || user.isAdmin || user.isManagingAdmin) &&
              $can({ key: 'providers', expectedPermission: 'edit' })
            "
          >
            <button
              class="mb-3 btn btn-theme"
              @click.prevent="$modal.show('change-password')"
            >
              <span
                ><i class="mr-2 fas fa-check-circle fa-key"></i>Update
                Password</span
              >
            </button>
            <save
              :saving="isSaving"
              v-if="user && !user.isPatient"
              classes="btn btn-black mb-3 ml-lg-3"
              >Update Profile &nbsp;&nbsp;&nbsp;&nbsp;</save
            >
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <AvatarForm
            @loaded="onLoad"
            :viewOnly="
              (user && user.isPatient) ||
              !$can({ key: 'providers', expectedPermission: 'edit' })
            "
            v-bind:avatarPath="providerData.profile_picture"
          ></AvatarForm>
        </div>
      </div>
    </form>
    <changePasswordModal
      :userId="
        user && (user.isAdmin || user.isManagingAdmin)
          ? parseInt(providerData.user_id)
          : 0
      "
    />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { Datetime } from "vue-datetime";
import ToggleConfidentailData from "./confidential-data-toggle.vue";
import AvatarForm from "@/components/AvatarForm.vue";
import changePasswordModal from "../../myProfile/update-password";
import VueTagsInput from "@johmun/vue-tags-input";
import Swal from "sweetalert2";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "ProviderBasic",
  components: {
    datetime: Datetime,
    ToggleConfidentailData,
    AvatarForm,
    changePasswordModal,
    VueTagsInput,
  },
  async mounted() {
    if (this.providerData && this.providerData.managers) {
      const userTags = [];
      this.providerData.managers.forEach((tag) => {
        userTags.push({ text: tag.name, value: tag.id, id: tag.id });
      });
      this.adminTags = userTags;
    } else {
      this.adminTags = [];
    }
    try {
      let users = [];
      const usersRes = await this.$http.get("admins-managers-info");
      usersRes.data.forEach((u) => {
        u = { text: u.name, value: u.id, id: u.id };
        users.push(u);
      });
      this.autocompleteItems = users;
    } catch (err) {
      //eslint-disable-next-line
      console.log(err);
    }
  },
  computed: {
    ...mapState({
      providers: (state) => state.providers,
      originalProviderData: (state) => state.providers.provider,
      user: (state) => state.auth.user,
    }),
    providerData: function () {
      return JSON.parse(JSON.stringify(this.originalProviderData));
    },
    id: function () {
      return this.$route.params.id;
    },
    filteredItems() {
      return this.autocompleteItems.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) != -1;
      });
    },
  },
  data() {
    return {
      statuses: [
        { value: "ACTIVE", label: "Active" },
        { value: "PENDING", label: "Pending" },
        { value: "INACTIVE", label: "Inactive" },
      ],
      cleave: {
        phone: { blocks: [20], numericOnly: true },
        sin: { blocks: [3, 3, 3], delimiter: " ", numericOnly: true },
      },
      genderOptions: [
        { value: "0", label: "Other" },
        { value: "1", label: "Male" },
        { value: "2", label: "Female" },
      ],
      tag: "",
      adminTags: [],
      autocompleteItems: [],
      isSaving: false,
    };
  },
  methods: {
    ...mapActions({
      updateProvider: "providers/update",
    }),
    onLoad(avatar) {
      this.providerData.profile_picture = avatar.src;
      this.$store.dispatch("providers/updatePhoto", {
        profile_picture: avatar.file,
      });
    },
    updateProfile: function () {
      const sin = this.providerData.sin
        ? this.providerData.sin.replace(/[^0-9]\s+/gi, "")
        : "";
      if (sin.length && sin.length != 9 && sin != "*****") {
        return Swal.fire({
          title: "S.I.N Validation",
          text: "S.I.N should be 9 digits",
          icon: "error",
        });
      }
      this.isSaving = true;
      this.$forceUpdate();
      const data = {
        provider: this.providerData.id,
        first:
          !this.user || this.user.isProvider
            ? this.originalProviderData.user.first
            : this.providerData.user.first,
        last:
          !this.user || this.user.isProvider
            ? this.originalProviderData.user.last
            : this.providerData.user.last,
        exited_at: this.providerData.exited_at
          ? this.providerData.exited_at
          : null,
        hired_at: this.providerData.hired_at
          ? this.providerData.hired_at
          : null,
        exitReason: this.providerData.exitReason,
        hstNumber: this.providerData.user.hstNumber,
        hcai_registration_id: this.providerData.hcai_registration_id,
        bio: this.providerData.user.bio,
        email: this.providerData.user.email,
        can_verify_event: this.providerData.can_verify_event,
        status: this.providerData.user.status,
        birth_date: this.providerData.user.birth_date || null,
      };
      if (this.providerData.user.phone_number) {
        data.phone_number = this.providerData.user.phone_number;
      }
      if (sin !== "*****") {
        data.sin = sin;
      }
      const userIds = [];
      this.adminTags.forEach((el) => {
        userIds.push(el.id);
      });
      data.administrator_ids = userIds;

      let profileData = this.providerData.user;
      profileData.noSuccessMessage = true;
      this.originalProviderData.user = profileData;

      this.$store
        .dispatch("Profile/update", profileData)
        .then((res) => {
          if (res) {
            this.updateProvider(data).then(() => {
              this.isSaving = false;
              this.$forceUpdate();
              this.$store.dispatch("providers/get", {
                id: this.id,
                noLoading: true,
                sin,
              });
            });
          } else {
            this.isSaving = false;
            this.$forceUpdate();
          }
        })
        .catch(() => {
          this.isSaving = false;
          this.$forceUpdate();
        });
    },
    getMonth(num) {
      return dayjs([2020, num, 1]).format("MMMM");
    },
  },
};
</script>
<style scoped>
.hintText {
  display: block;
  margin-top: -1.375rem;
  margin-bottom: 1.375rem;
}
#input-control-enable-second-verification,
#input-control-can-verify {
  transform: scale(0.5);
  width: 45px;
}
.col-md-1.row {
  min-width: 100px;
}
</style>
<style lang="scss">
#administrator_ids {
  min-height: 4px;
  padding: 0;
  .ti-input {
    border: 0;
    .ti-tags {
      li {
        height: 32px;
        margin-top: 0;
      }
    }
  }
  .ti-new-tag-input-wrapper input {
    height: 100%;
  }
}
@media (max-width: 1024px) {
  .profile-outer-page-container {
    flex-direction: column-reverse;
  }
  .profile-action-container {
    display: flex;
    flex-direction: column;
  }
}
</style>
