<template>
  <modal
    name="change-password"
    transition="pop-out"
    :width="400"
    :focus-trap="true"
    :height="310"
    @closed="clearData"
  >
    <div class="box">
      <div id="bp-left">
        <div class="partition" id="partition-register">
          <div class="partition-title">Update Password</div>
          <div class="partition-form">
            <form autocomplete="false">
              <input
                v-if="!userId"
                v-model="resetData.currentPassword"
                type="password"
                placeholder="Current Password"
              />
              <input
                v-model="resetData.newPassword"
                type="password"
                placeholder="New Password"
              />
              <input
                v-model="resetData.confirmPassword"
                type="password"
                placeholder="Confirm Password"
              />
              <span class="box-error-message" v-show="passwordNotMatched"
                >New and Confirm Password are not matching</span
              >
            </form>
            <button
              type="submit"
              class="mt-3 btn btn-theme updatePasswordBtn"
              @click.prevent="savePassword"
              :disabled="inValidInputs || isSubmitting"
            >
              <span
                ><i
                  class="mr-2 fas fa-check-circle fa-fw"
                  v-if="!isSubmitting"
                ></i>
                <i class="mr-2 fa fa-spin fa-circle-notch" v-else></i
                >Update</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "changePasswordModal",
  props: {
    userId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      resetData: {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      isSubmitting: false,
    };
  },

  computed: {
    ...mapState(["Profile"]),
    passwordNotMatched() {
      return (
        this.resetData.newPassword != "" &&
        this.resetData.confirmPassword != "" &&
        this.resetData.newPassword != this.resetData.confirmPassword
      );
    },
    inValidInputs() {
      return (
        this.passwordNotMatched ||
        (!this.userId && !this.resetData.currentPassword)
      );
    },
  },
  methods: {
    savePassword: function () {
      if (!this.inValidInputs) {
        if (this.userId) {
          this.resetData.userId = this.userId;
        }
        this.isSubmitting = true;
        this.$store
          .dispatch("Profile/changePassword", this.resetData)
          .then((result) => {
            if (result) {
              this.isSubmitting = false;
              this.$modal.hide("change-password");
            } else {
              this.isSubmitting = false;
            }
          })
          .catch(() => {
            this.isSubmitting = false;
          });
      }
    },
    clearData: function () {
      this.resetData = {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      };
    },
  },
};
</script>
<style lang="scss">
.box {
  background: white;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0 0 40px black;
  color: #8b8c8d;
  font-size: 0;

  .updatePasswordBtn {
    margin-top: 15px;
    width: 100%;
  }

  .box-error-message {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    height: 0;
    line-height: 32px;
    padding: 0 12px;
    text-align: center;
    width: 100%;
    font-size: 11px;
    color: white;
    background: #f38181;
  }
  .partition {
    width: 100%;
    height: 100%;
    .partition-title {
      box-sizing: border-box;
      padding: 30px;
      width: 100%;
      text-align: center;
      letter-spacing: 1px;
      font-size: 20px;
      font-weight: 300;
    }
    .partition-form {
      padding: 0 20px;
      box-sizing: border-box;
    }
  }
  input[type="password"],
  input[type="text"] {
    display: block;
    box-sizing: border-box;
    margin-bottom: 4px;
    width: 100%;
    font-size: 12px;
    line-height: 2;
    border: 0;
    border-bottom: 1px solid #dddedf;
    padding: 4px 8px;
    font-family: inherit;
    transition: 0.5s all;
  }

  button {
    background: white;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    letter-spacing: 1px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    min-width: 140px;
    margin-top: 8px;
    color: #8b8c8d;
    cursor: pointer;
    border: 1px solid #dddedf;
    text-transform: uppercase;
    transition: 0.1s all;
    font-size: 10px;
    &:hover {
      border-color: mix(#dddedf, black, 90%);
      color: mix(#8b8c8d, black, 80%);
      background-color: white;
    }
  }
  .large-btn {
    width: 100%;
    background: white;
    span {
      font-weight: 600;
    }
    &:hover {
      color: white !important;
    }
  }
}
.pop-out-enter-active,
.pop-out-leave-active {
  transition: all 0.5s;
}
.pop-out-enter,
.pop-out-leave-active {
  opacity: 0;
  transform: translateY(24px);
}
.form-group {
  align-items: center;
}
</style>
